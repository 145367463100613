var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"my-2",attrs:{"no-gutters":_vm.$vuetify.breakpoint.xsOnly,"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.pages.site.form.fields.siteName'),"rules":"required|min:2|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.site.form.fields.siteName'),"dense":"","outlined":""},model:{value:(_vm.siteData.name),callback:function ($$v) {_vm.$set(_vm.siteData, "name", $$v)},expression:"siteData.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.pages.site.form.fields.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.site.form.fields.email'),"dense":"","outlined":""},model:{value:(_vm.siteData.email),callback:function ($$v) {_vm.$set(_vm.siteData, "email", $$v)},expression:"siteData.email"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.pages.site.form.fields.companyNameEn'),"rules":"required|min:2|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.site.form.fields.companyNameEn'),"dense":"","outlined":""},model:{value:(_vm.siteData.company_name_en),callback:function ($$v) {_vm.$set(_vm.siteData, "company_name_en", $$v)},expression:"siteData.company_name_en"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.pages.site.form.fields.companyNameAr'),"rules":"required|min:2|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.site.form.fields.companyNameAr'),"dense":"","outlined":""},model:{value:(_vm.siteData.company_name_ar),callback:function ($$v) {_vm.$set(_vm.siteData, "company_name_ar", $$v)},expression:"siteData.company_name_ar"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('kurcc-autocomplete',{attrs:{"items":_vm.categories,"loading":_vm.fetchingCategories,"multiple":false,"name":_vm.$vuetify.lang.t('$vuetify.pages.site.form.fields.category'),"selected-items":_vm.siteData.category,"dense":"","outlined":""},on:{"update:selectedItems":function($event){return _vm.$set(_vm.siteData, "category", $event)},"update:selected-items":function($event){return _vm.$set(_vm.siteData, "category", $event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }