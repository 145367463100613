<template>
  <v-row :no-gutters="$vuetify.breakpoint.xsOnly" align="center" class="my-2">
    <v-col cols="12" md="4" sm="6">
      <validation-provider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.pages.site.form.fields.siteName')"
                           rules="required|min:2|max:50">
        <v-text-field v-model="siteData.name" :error-messages="errors"
                      :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.siteName')" dense outlined/>
      </validation-provider>
    </v-col>
    <v-col cols="12" md="4" sm="6">
      <validation-provider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.pages.site.form.fields.email')"
                           rules="required|email">
        <v-text-field v-model="siteData.email" :error-messages="errors"
                      :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.email')" dense outlined/>
      </validation-provider>
    </v-col>
    <v-col cols="12" md="4" sm="6">
      <validation-provider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.pages.site.form.fields.companyNameEn')"
                           rules="required|min:2|max:50">
        <v-text-field v-model="siteData.company_name_en" :error-messages="errors"
                      :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.companyNameEn')" dense outlined/>
      </validation-provider>
    </v-col>
    <v-col cols="12" md="4" sm="6">
      <validation-provider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.pages.site.form.fields.companyNameAr')"
                           rules="required|min:2|max:50">
        <v-text-field v-model="siteData.company_name_ar" :error-messages="errors"
                      :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.companyNameAr')" dense outlined/>
      </validation-provider>
    </v-col>
    <v-col cols="12" md="4" sm="6">
      <kurcc-autocomplete :items="categories" :loading="fetchingCategories" :multiple="false"
                          :name="$vuetify.lang.t('$vuetify.pages.site.form.fields.category')"
                          :selected-items.sync="siteData.category"
                          dense outlined/>
    </v-col>
  </v-row>
</template>

<script>
import Site from '@/modules/app/models/site'

export default {
  name: 'KurccSiteStepperStep1Content',
  components: {
    KurccAutocomplete: () => import('@/modules/app/components/KurccAutocomplete')
  },
  props: {
    site: {
      type: Site,
      required: true
    }
  },
  data () {
    return {
      categories: [],
      fetchingCategories: false
    }
  },
  computed: {
    siteData: {
      get () {
        return this.site
      },
      set (v) {
        this.$emit('update:site', v)
      }
    }
  },
  methods: {
    getAllCategories () {
      this.fetchingCategories = true
      this.$store.dispatch('getAllCategories', { withIncludes: false })
        .then(res => {
          this.categories = res.items
        })
        .finally(() => {
          this.fetchingCategories = false
        })
    }
  },
  created () {
    this.getAllCategories()
  }
}
</script>
